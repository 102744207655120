
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/environmentaltest";

@Options({
  name: "Detailinfo",
})
export default class extends Vue {
  detailInfo: any = {};
  showDialog = false;
  check = false;

  histopryList = [];
  isEmpty = false;
  mounted() {
    this.detailInfo = this.$route.params;
    this.check = this.detailInfo.outputStatus == 1 ? false : true;
  }
  // 点击即开启/关闭设备
  setStatus(value: any) {
    this.detailInfo.outputStatus = value ? "0" : "1";
    let data = {
      id: this.detailInfo.id,
      deviceAddr: this.detailInfo.deviceAddr,
      opt: value ? "0" : "1",
      isAlarm: 0,
    };
    api.operateDevice(data).then((res: any) => {
      if (res.code === 0) {
        this.$toast.success(res.msg);
      }
      if (res.code === 1) {
        this.$toast.fail(res.msg);
        this.check = value;
      }
    });
  }
  fOpenDialog() {
    api.getDeviceHistory({ deviceId: this.detailInfo.id }).then((res: any) => {
      if (res.code === 0) {
        this.histopryList = res.data;
      }
      this.isEmpty = this.histopryList.length > 0 ? false : true;
      this.showDialog = true;
    });
  }
}
