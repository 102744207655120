import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-632a7fae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  key: 1,
  class: "history"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_switch = _resolveComponent("van-switch")!
  const _component_van_empty = _resolveComponent("van-empty")!
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_nav_bar, {
        title: _ctx.$route.meta.title,
        "left-arrow": "",
        onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
        fixed: ""
      }, {
        right: _withCtx(() => [
          _createVNode(_component_van_icon, {
            name: "clock-o",
            size: "20px",
            onClick: _ctx.fOpenDialog
          }, null, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_van_cell, { title: "设备名称:" }, {
        value: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.detailInfo.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_van_cell, { title: "设备编号:" }, {
        value: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.detailInfo.deviceKey), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_van_cell, { title: "设备地址:" }, {
        value: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.detailInfo.deviceAddr), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_van_cell, { title: "设备状态:" }, {
        value: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.detailInfo.deviceStatus == 0
            ? "未运行"
            : _ctx.detailInfo.deviceStatus == 1
            ? "离线"
            : "在线"), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_van_cell, { title: "开关状态:" }, {
        value: _withCtx(() => [
          (_ctx.detailInfo.deviceStatus == 2)
            ? (_openBlock(), _createBlock(_component_van_switch, {
                key: 0,
                modelValue: _ctx.check,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.check) = $event)),
                "active-color": "#09BB07",
                size: "20px",
                onChange: _ctx.setStatus
              }, null, 8, ["modelValue", "onChange"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_van_dialog, {
      show: _ctx.showDialog,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
      title: "历史记录",
      "show-cancel-button": "",
      "show-confirm-button": false,
      cancelButtonText: "关闭"
    }, {
      default: _withCtx(() => [
        (_ctx.isEmpty)
          ? (_openBlock(), _createBlock(_component_van_empty, {
              key: 0,
              class: "custom-image",
              image: require('../../../assets/empty-bg.png'),
              description: "没有历史记录"
            }, null, 8, ["image"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.histopryList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "history-row",
                  key: index
                }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["dot", { _close: item.setType != 0 }])
                    }, null, 2),
                    _createTextVNode(_toDisplayString(item.createTime), 1)
                  ]),
                  _createElementVNode("span", null, _toDisplayString(item.setType == 0 ? "开启" : "关闭"), 1)
                ]))
              }), 128))
            ]))
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}